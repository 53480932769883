import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { register } from "../Redux/Actions/userActions";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import Toast from "../components/LoadingError/Toast";
import { toast } from "react-toastify";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const Register = () => {
  // window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: true,
    autoClose: 2000,
  };

  const [searchParams, setSearchParams] = useSearchParams()
  const {location} = useLocation();
  // console.log(`useLocation: ${location}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = location?.search ? location?.search.split("=")[1] : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  useEffect(() => {
    if(userInfo){
    // console.log(JSON.stringify(userInfo));
    if (!userInfo.message) {
      // console.log(`inside useEffect if(userInfo), redirect: ${redirect}`);
      navigate(redirect);
    }
  }
  }, [userInfo, navigate, redirect]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~./?])[A-Za-z\d!@#$%^&*()_\-+=~./?]{8,}$/;
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Passwords do not match", Toastobjects);
      }
    }
    else if (password === "") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password cannot be empty", Toastobjects);
      }
    }
    else if (!regExp.test(password)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password must be 8 characters long with at least one lowercase, one uppercase, one digit, and a special character.", Toastobjects);
      }
    }
    else {
      // console.log("hi");
      try {
        const result = await dispatch(register(name, email, password));
        // console.log(`result:`, result);
        // console.log(`result.type:`, result.type);
      
        // Check if registration was successful
        const registrationSuccess = result.type === 'USER_REGISTER_SUCCESS';
        // console.log(`registrationSuccess:`, registrationSuccess);
      
        if (registrationSuccess) {
          // console.log("Registration successful!");
          // Redirect to '/pending' only if registration was successful
          navigate('/pending');
        } else {
          // Handle registration failure if needed
          console.error("Registration failed:", result.payload);
          // Display an error toast, for example
          toast.error("Registration failed", Toastobjects);
        }
      } catch (error) {
        // Handle any errors that occur during the asynchronous operation
        console.error("An error occurred during registration:", error);
        // Display a generic error toast, for example
        toast.error("An error occurred during registration", Toastobjects);
      }

      }
    
    
  };

  return (
    <>
      <Toast />
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}

        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <input
            type="text"
            placeholder="Username"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <br/><br/>
          <h6>By registering, you agree to these <a href="/terms" class="text-primary text-decoration-underline">Terms and Conditions</a></h6>
          <button type="submit">Register</button>
          <p>
            <Link to="/login">
              Have an account? <strong>Login</strong>
            </Link>
          </p>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default Register;
