import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGuestOrder } from './../Redux/Actions/OrderActions'
import Header from "./../components/Header";
import Footer from "./../components/Footer";



const GuestCheckout = () => {
  const [shippingInfo, setShippingInfo] = useState({
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    // Calculate Price
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    cart.itemsPrice = addDecimals(
      cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }, [cart]);

  const handleShippingInfoChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo({ ...shippingInfo, [name]: value });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const order = {
      orderItems: cart.cartItems,
      shippingAddress: shippingInfo,
      email,
    };

    dispatch(createGuestOrder(order.orderItems, shippingInfo, email));
  };

  return (
    <>
      <Header />
      <div className="container d-flex justify-content-center align-items-center login-center">
        <form className="Login col-md-8 col-lg-4 col-11" onSubmit={handleSubmit}>
          <h6>GUEST CHECKOUT</h6>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            required
            onChange={handleEmailChange}
          />
          <small className="text-muted">
            Please verify your email address. This is where your confirmation email will be sent.
          </small>
          <input
            type="text"
            placeholder="Enter address"
            value={shippingInfo.address}
            required
            onChange={handleShippingInfoChange}
            name="address"
          />
          <input
            type="text"
            placeholder="Enter city"
            value={shippingInfo.city}
            required
            onChange={handleShippingInfoChange}
            name="city"
          />
          <input
            type="text"
            placeholder="Enter state"
            value={shippingInfo.state}
            required
            onChange={handleShippingInfoChange}
            name="state"
          />
          <input
            type="text"
            placeholder="Enter postal code"
            value={shippingInfo.postalCode}
            required
            onChange={handleShippingInfoChange}
            name="postalCode"
          />
          <input
            type="text"
            placeholder="Enter country"
            value={shippingInfo.country}
            required
            onChange={handleShippingInfoChange}
            name="country"
          />
          <button type="submit">Place Order</button>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default GuestCheckout;
